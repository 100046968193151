import axios from 'axios';  
import router from '../router';
  
// process.env.VUE_APP_BASE_API
// 创建一个 Axios 实例  
const service = axios.create({  
  baseURL: 'https://www.aiyuservice.com/api/', // 可以在这里设置你的基础 URL  
  timeout: 5000, // 请求超时时间  
});  
  
// 请求拦截器（可选）  
service.interceptors.request.use(  
  config => {  
    // config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');  
    return config;  
  },  
  error => {  
    // 对请求错误做些什么  
    console.log(error); // for debug  
    return Promise.reject(error);  
  }  
);  
  
// 响应拦截器（可选）  
service.interceptors.response.use(  
  response => {  
    // 对响应数据做点什么  
    const res = response.data; 
    
    if (res.errorCode === 6666) {   
      // router.push("/Whitepage"); 
      // 例如，检查组件是否已卸载  
      // return Promise.reject(new Error('ErrorCode 6666: Redirecting to specified page'));  
    }  
    return res; // 返回响应数据  
  },  
  error => {  
    // 对响应错误做点什么  
    console.log('err' + error); // for debug  
    return Promise.reject(error);  
  }  
);  
  
// 封装 get 请求  
export function get(url, params = {}, headers = {}) {  
  return service.get(url, {   
    params,
    headers: {  
      ...service.defaults.headers,  
      ...headers,  
    },  
  }); 
}  
  
// 封装 post 请求  
export function post(url, data = {}, headers = {}) {  
  return service.post(url,data, {  
    headers: {  
      ...service.defaults.headers,  
      ...headers,  
    },  
  });
}  