import { createRouter, createWebHistory } from 'vue-router'  
 
import Overtimeorder from '../views/Overtimeorder.vue'  
import Longtailorder from '../views/Longtailorder.vue'
import Earlywarnorder from '../views/Earlywarnorder.vue'
import Oneselfbuildorder from '../views/Oneselfbuildorder.vue'
import Orderlist from '../views/Orderlist.vue'
import OrderPOList from '../views/OrderPOList.vue'
import Detail from '../views/Detail.vue'
import ChatPage from '../views/chatPage.vue'
import ComputerRoom from '../views/ComputerRoom.vue'
import Whitepage from '../views/whitepage.vue'
import OperaDashboard from '../views/operaDashboard.vue'
import TPCDashboard from '../views/TPCDashboard.vue'

const routes = [  
  {  
    path: '/Overtimeorder',  
    name: 'overtimeorder',  
    component: Overtimeorder  
  },  
  {  
    path: '/Longtailorder',  
    name: 'longtailorder',  
    component: Longtailorder  
  }, 
  {  
    path: '/Earlywarnorder',  
    name: 'earlywarnorder',  
    component: Earlywarnorder  
  }, 
  {  
    path: '/Oneselfbuildorder',  
    name: 'oneselfbuildorder',  
    component: Oneselfbuildorder  
  }, 
  {  
    path: '/Orderlist',  
    name: 'orderlist',  
    component: Orderlist  
  },
  {  
    path: '/OrderPOList',  
    name: 'OrderPOList',  
    component: OrderPOList  
  },
  {  
    path: '/ChatPage',  
    name: 'chatPage',  
    component: ChatPage  
  },
  {  
    path: '/OperaDashboard',  
    name: 'operaDashboard',  
    component: OperaDashboard  
  }, 
  {  
    path: '/TPCDashboard',  
    name: 'TPCDashboard',  
    component: TPCDashboard  
  },
  {  
    path: '/ComputerRoom',  
    name: 'computerRoom',  
    component: ComputerRoom  
  },
  {  
    path: '/detail/:id', // 使用动态路由参数  
    name: 'Detail',  
    component: Detail  
  },
  {  
    path: '/Whitepage',  
    name: 'whitepage',  
    component: Whitepage
  }, 
]  
  
const router = createRouter({  
  history: createWebHistory(), 
  routes 
})  
router.beforeEach((to, from, next) => {  
  // 在离开路由前保存当前路由的路径 
  if (to.path) {  
    localStorage.setItem('activeIndex', to.path);  
    next(); 
  }
});  
export default router